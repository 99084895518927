import axios from 'axios';
import { hostUrl } from '../config.js';
import {
  IEpSubsDBSetting_groupValue,
  IEpSubsDBSettingPost,
  IEpSubsDBSettingValue,
} from '../models';

export const getSettingsGroupList = async (): Promise<IEpSubsDBSetting_groupValue[]> => {
  return axios.get(`${hostUrl}/settings/groups`).then(response => {
    return response.data;
  });
};

// get settings rules by group code
export const getSettingsRules = async (
  groupCode: string,
): Promise<IEpSubsDBSettingValue[]> => {
  return axios.get(`${hostUrl}/settings/groups/${groupCode}`).then(response => {
    return response.data;
  });
};

export const updateSettingsRule = async (
  ruleCode: string,
  data: IEpSubsDBSettingPost,
): Promise<{
  code: number;
  message: string;
}> => {
  return axios.patch(`${hostUrl}/settings/${ruleCode}`, data).then(response => {
    return response.data;
  });
};

export const lockSettingsRule = async (ruleCode: string): Promise<boolean> => {
  return axios.post(`${hostUrl}/settings/${ruleCode}/lock`, {}).then(() => {
    return true;
  });
};

export const unlockSettingsRule = async (ruleCode: string): Promise<boolean> => {
  return axios.post(`${hostUrl}/settings/${ruleCode}/unlock`, {}).then(() => {
    return true;
  });
};

export const searchEngine = async (numberOfDays?: number): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/admin/reindex-recent-es?since_last_days=${numberOfDays}`)
    .then(() => {
      return true;
    });
};

export const searchEngineWithDateRange = async (
  start_dt?: string,
  end_dt?: string,
): Promise<boolean> => {
  return axios
    .post(`${hostUrl}/admin/reindex-recent-es?start_dt=${start_dt}&end_dt=${end_dt}`)
    .then(() => {
      return true;
    });
};

export const searchEngineValidation = async (): Promise<{
  status: string;
  message: string;
  error: string;
}> => {
  return axios.post(`${hostUrl}/elastic/validate?rows=100&show_detail=true`).then(res => {
    return res.data;
  });
};

export const syncSubqueue = async (start: string, end: string): Promise<string> => {
  return axios
    .post(`${hostUrl}/plugin/subqueue/sync`, {
      start: start,
      end: end,
    })
    .then(response => {
      return response.data;
    });
};

export const syncSubqueueVerify = async (
  start: string,
  end: string,
): Promise<{
  start_date: string;
  end_date: string;
  db_count: number;
  is_sync: boolean;
  subqueue_count: number;
}> => {
  return axios
    .get(`${hostUrl}/plugin/subqueue/verify?start_date=${start}&end_date=${end}`)
    .then(res => {
      return res.data;
    });
};

interface timeMaskResponse {
  message: string;
  data: boolean;
}
export const enableTimeMask = async (
  id: number,
  data: string,
): Promise<timeMaskResponse> => {
  const bodyFormData = new FormData();
  bodyFormData.append('time-mask', `${data}`);
  return axios
    .put(`${hostUrl}/admin/scheduled-tasks/${id}/time-mask`, bodyFormData)
    .then(response => {
      return response.data;
    });
};
