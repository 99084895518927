import { atom, atomFamily, selectorFamily } from 'recoil';
import {
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import { selector } from 'recoil';
import * as H from 'history';
import {
  genTransactionData,
  genTransactionListData,
} from '../../services/epSubsTransactionService';
import {
  getParamsFromSearch,
  ListState,
} from '../../components/tables/EpFullFeaturedTable';
import { offsetToPageId } from '../../components/tables/EpFullFeaturedTable/Pagination/Pagination';
import {
  IEpSubsCompositeDBTransactionValue,
  IEpSubsCompositeDBTransactionValueInList,
  IEpSubsDBTransactionValueList,
  TEpSubsDBTransactionValueKeys,
} from 'src/models';
import { DEBUGGING } from 'src/config';
import {
  DEFAULT_TRANSACTION_SEARCH_OPTION,
  TRANSACTION_STATUS_FILTER_GROUP_FIELD,
} from 'src/containers/transactions/EpSubsTransactionNav';
import { getSimpleFilterData } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';

// ************************************************ //
//  Transaction List Latest Search Parameter States  //
// ************************************************ //
export const DEFAULT_TRANSACTION_SEARCH_PARAMETER = `?filter=${JSON.stringify(
  DEFAULT_TRANSACTION_SEARCH_OPTION,
)}`;
/**
 * An atom that stores the latest transaction search param
 */
export const latestTransactionSearchParamAtom = atom<TLocationRecord>({
  key: 'latestTransactionSearchParamAtom',
  default: { search: DEFAULT_TRANSACTION_SEARCH_PARAMETER, ready: false },
});

// *********************************************** //
//       Transaction List Search Result States      //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for transaction list
 */
export const transactionSearchRequestIDAtom = atom<number>({
  key: 'transactionSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the basic data for transaction list based on current searchParam
 */
export const transactionBasicListSelector = selector<
  ListState<IEpSubsCompositeDBTransactionValueInList>
>({
  key: 'transactionIdListSelector',
  get: async ({ get }) => {
    const transactionSearchParam = get(latestTransactionSearchParamAtom);

    // fetch transactions
    if (transactionSearchParam.ready && transactionSearchParam.search !== '') {
      const routeParams = getParamsFromSearch<TEpSubsDBTransactionValueKeys>(
        transactionSearchParam.search as H.Search,
      );
      const { order, sort, page, perPage, simpleQuery } = routeParams;
      const filter = decodeURIComponent(routeParams.filter);
      const offset = (page - 1) * perPage;
      const filterData = getSimpleFilterData(filter);

      try {
        const response = await genTransactionListData(
          perPage,
          offsetToPageId(offset, perPage),
          sort === ''
            ? [{ key: 'transaction_date', ascending: 'DESC' }]
            : [{ key: sort, ascending: order }],
          order ? order : 'DESC',
          simpleQuery,
          filterData[TRANSACTION_STATUS_FILTER_GROUP_FIELD][0],
        );

        DEBUGGING &&
          console.log('recoil, transactionBasicListSelector response', response);

        return {
          loading: false,
          err: NO_ERROR_IN_TABLE,
          data:
            response.data.map(transaction => {
              return {
                transaction: transaction,
              } as IEpSubsCompositeDBTransactionValueInList;
            }) ?? [],
          total: response.total ?? 0,
        };
      } catch (error) {
        console.log(`error`, error);
        return {
          loading: false,
          err: (error as Error).message,
          data: [],
          total: 0,
        };
      }
    }
    return {
      loading: false,
      err: NO_ERROR_IN_TABLE,
      data: [],
      total: 0,
    };
  },
});

/**
 * An selector that stores the transaction detail list based on current searchParam
 */
export const transactionCompleteListSelector = selector<
  ListState<IEpSubsDBTransactionValueList>
>({
  key: 'transactionCompleteListSelector',
  get: async ({ get }) => {
    const idListState = get(transactionBasicListSelector);

    if (!idListState.err) {
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: (idListState.data.map(index => index.transaction) as any) ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from transactionBasicListSelector
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: [],
        total: 0,
      };
    }
  },
});

// *********************************************** //
//        Transaction Detail Family States        //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each transaction
 */
export const transactionRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'transactionRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores subscription details for each subscription
 */
export const transactionSelectors = selectorFamily<
  IEpSubsCompositeDBTransactionValue,
  number
>({
  key: 'transactionSelectors',
  get:
    transactionId =>
    async ({ get }) => {
      get(transactionRefreshRequestIDAtoms(transactionId)); // Add request ID as a dependency
      const transactionData = await genTransactionData(transactionId);
      DEBUGGING &&
        console.log('recoil, transactionSelectors transactionData:', transactionData);
      return transactionData;
    },
});
