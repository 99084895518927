import { atom, atomFamily } from 'recoil';
import {
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import { selector, selectorFamily } from 'recoil';
import {
  IEpSubsCompositeDBSubscriptionValueInList,
  IEpSubsCompositeDBSubscriptionValue,
  IEpSubsDBSubscriptionValueList,
} from '../../models/';
import { genSubscriptionData } from '../../services/epSubsSubscriptionService';
import { ListState } from '../../components/tables/EpFullFeaturedTable';
import {
  basicFilterEnabledSearchListSelectorsFamily,
  customerDetailEnabledAtomFamily,
  customerSelectors,
} from '..';
import { getNoFilterOptionBySource } from 'src/components/navBars/EpSubsListNav/EpSubsListNav';
import { getDefaultFilterSearchParam } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';

// ************************************************ //
// Subscription List Latest Search Parameter States //
// ************************************************ //
export const DEFAULT_SUBSCRIPTION_SEARCH_PARAMETER = getDefaultFilterSearchParam(
  getNoFilterOptionBySource('subscription')?.filterJson,
);

/**
 * An atom that stores the latest subscription search param
 */
export const latestSubscriptionSearchParamAtom = atom<TLocationRecord>({
  key: 'latestSubscriptionSearchParamAtom',
  default: { search: DEFAULT_SUBSCRIPTION_SEARCH_PARAMETER, ready: false },
});

// *********************************************** //
//      Subscription List Search Result States     //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for subscription list
 */
export const subscriptionSearchRequestIDAtom = atom<number>({
  key: 'subscriptionSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the subscription detail list based on current searchParam
 */
export const subscriptionCompleteListSelector = selector<
  ListState<IEpSubsDBSubscriptionValueList>
>({
  key: 'subscriptionCompleteListSelector',
  get: async ({ get }) => {
    // const idListState = get(subscriptionBasicListSelector);
    const idListState = get(
      basicFilterEnabledSearchListSelectorsFamily('subscription'),
    ) as ListState<IEpSubsCompositeDBSubscriptionValueInList>;

    if (!idListState.err) {
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: (idListState.data.map(index => index.subscription) as any) ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from subscriptionBasicListSelector
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: [],
        total: 0,
      };
    }
  },
});

// *********************************************** //
//        Subscription Detail Family States        //
// *********************************************** //

/**
 * An atomFamily that stores the refresh Request ID for each subscription
 */
export const subscriptionRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'subscriptionRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores subscription details for each subscription
 */
export const rawSubscriptionSelectors = selectorFamily<
  IEpSubsCompositeDBSubscriptionValue,
  number
>({
  key: 'rawSubscriptionSelectors',
  get:
    subscriptionId =>
    async ({ get }) => {
      get(subscriptionRefreshRequestIDAtoms(subscriptionId)); // Add request ID as a dependency
      const subscriptionData = await genSubscriptionData(subscriptionId);
      return subscriptionData;
    },
});

/**
 * An selectorFamily that stores subscription details for each subscription
 */
export const subscriptionSelectors = selectorFamily<
  IEpSubsCompositeDBSubscriptionValue,
  number
>({
  key: 'subscriptionSelectors',
  get:
    subscriptionId =>
    async ({ get }) => {
      const rawSubscriptionData = get(rawSubscriptionSelectors(subscriptionId));
      // Add customer basic data dependency if enabled
      const customerId = rawSubscriptionData.subscription.customer_id;
      const customerDetailEnabled = get(customerDetailEnabledAtomFamily(customerId));
      if (customerDetailEnabled) {
        const customerBasicData = get(
          customerSelectors(rawSubscriptionData.subscription.customer_id),
        ).customer;
        return { ...rawSubscriptionData, customer: customerBasicData };
      }
      return rawSubscriptionData;
    },
});
