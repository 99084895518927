import React, { BaseSyntheticEvent, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useLogin } from '../../recoil';
import style from './EpSubsLogin.module.scss';
import { DB_RESPONSE_FAILED } from '../../models/epSubsDBGenTypes';
import EpSubsForgotPassword from './EpSubsForgotPassword';
import { EpIcon } from 'src/assets';
import EpSubsSpinnerModal from 'src/components/spinnerModal/EpSubsSpinnerModal';

const EpSubsLogin = (): JSX.Element => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');

  const login = useLogin();

  function validateForm() {
    return email != undefined && email?.trim().length > 0 && password.length > 0;
  }

  const handleSubmit = async (event: BaseSyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    const resp = await login({
      user: email,
      password: password,
    });
    setIsLoading(false);
    if (resp.status === DB_RESPONSE_FAILED) {
      setErrorMessage(resp.error);
    }
  };

  return (
    <div className="Login">
      <h1 className={style.login_header}>
        <EpIcon name="conductor" style={{ height: '150px' }} />
      </h1>

      <Form noValidate onSubmit={handleSubmit} id={style.login_form} autoComplete={'off'}>
        <h3 className="mb-3 text-center" style={{ fontWeight: 'bold' }}>
          Log In
        </h3>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setErrorMessage('');
            }}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setErrorMessage('');
            }}
          />
        </Form.Group>
        <Button block type="submit" disabled={!validateForm()}>
          Login
        </Button>
        {errorMessage && <div className={style.error}>{errorMessage}</div>}
      </Form>
      <br></br>
      <EpSubsForgotPassword style={{ maxWidth: '400px', margin: 'auto' }} />
      <EpSubsSpinnerModal isLoading={isLoading} />
    </div>
  );
};

export default EpSubsLogin;
