import { atomFamily, selector, selectorFamily } from 'recoil';
import * as H from 'history';
import {
  getParamsFromSearch,
  ListState,
  NO_ERROR_IN_TABLE,
} from 'src/components/tables/EpFullFeaturedTable';
import {
  IEpSubsCompositeDBCustomerValueInList,
  IEpSubsCompositeDBInvoiceValueInList,
  IEpSubsCompositeDBSubscriptionValueInList,
  IEpSubsDBCustomerValue,
  IEpSubsDBElastic_saved_filtersValue,
  IEpSubsDBInvoiceValue,
  IEpSubsDBSubscriptionValue,
  TEpSubsDBCustomerValueKeys,
  TEpSubsDBElastic_ui_paramsApiValue,
  TEpSubsDBInvoiceValueKeys,
  TEpSubsDBResponseOk,
  TEpSubsDBSubscriptionValueKeys,
  // TFilterData,
  TFilterEntry,
} from 'src/models';
import {
  // genCustomerListData,
  genFilteredSearchData,
  genFilterUiParameters,
  // genInvoiceListData,
  genSavedFilteredSearchData,
  genSavedFilterList,
  // genSubscriptionListData,
} from 'src/services';
// import { putNoty } from 'src/utilities/epSubsNoty';
import { latestCustomerSearchParamAtom } from '..';
import { latestInvoiceSearchParamAtom } from '../invoice/invoiceListPageStates';
import { latestSubscriptionSearchParamAtom } from '../subscription/subscriptionListPageStates';
// import { offsetToPageId } from 'src/components/tables/EpFullFeaturedTable/Pagination/Pagination';
import { addPrefixForFilterField } from './filterUtils';
import { TFilterSource } from 'src/js/constants/subs';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//           Filter UI Parameter States            //
// *********************************************** //
export const filterUiParameterSelector = selector<TEpSubsDBElastic_ui_paramsApiValue[]>({
  key: 'filterUiParameterSelector',
  get: async () => {
    const rawUiParametersObject = await genFilterUiParameters();
    return Object.values(rawUiParametersObject).flat();
  },
});

// *********************************************** //
//              Saved Filter States                //
// *********************************************** //
export const savedFilterListRequestIdFamily = atomFamily<number, TFilterSource>({
  key: 'savedFilterListRequestIdFamily',
  default: 0,
});

export const savedFilterListSelectorFamily = selectorFamily<
  IEpSubsDBElastic_saved_filtersValue[],
  TFilterSource
>({
  key: 'savedFilterListSelectorFamily',
  get:
    source =>
    async ({ get }) => {
      get(savedFilterListRequestIdFamily(source)); // Used to refresh

      const savedFilterList = await genSavedFilterList(source);
      return savedFilterList;
    },
});

// *********************************************** //
//           Filter Search Result States           //
// *********************************************** //
/**
 * An atom that stores that stores the refresh Request ID for invoice list
 */
export const searchRequestIDAtomsFamily = atomFamily<number, TFilterSource>({
  key: 'searchRequestIDAtomsFamily',
  default: 0,
});

/**
 * An selector that stores the basic data for invoice list based on current searchParam
 */
type TRecord =
  | IEpSubsDBCustomerValue
  | IEpSubsDBSubscriptionValue
  | IEpSubsDBInvoiceValue;
type TRecordInList =
  | IEpSubsCompositeDBCustomerValueInList
  | IEpSubsCompositeDBSubscriptionValueInList
  | IEpSubsCompositeDBInvoiceValueInList;
type TRecordKeys =
  | TEpSubsDBCustomerValueKeys
  | TEpSubsDBSubscriptionValueKeys
  | TEpSubsDBInvoiceValueKeys;
export const basicFilterEnabledSearchListSelectorsFamily = selectorFamily<
  ListState<TRecordInList>,
  Exclude<TFilterSource, 'plan' | 'addon' | 'coupon' | 'emailLog' | 'user'>
>({
  key: 'basicFilterEnabledSearchListSelectorsFamily',
  get:
    source =>
    async ({ get }) => {
      const requestID = get(searchRequestIDAtomsFamily(source)); // Add request ID as a dependency
      let sourceSearchParam;
      switch (source) {
        case 'customer':
          sourceSearchParam = get(latestCustomerSearchParamAtom);
          break;
        case 'subscription':
          sourceSearchParam = get(latestSubscriptionSearchParamAtom);
          break;
        case 'invoice':
          sourceSearchParam = get(latestInvoiceSearchParamAtom);
          break;
      }
      // const sourceSearchParam = get(latestFilteredSearchParamAtomsFamily(source));
      DEBUGGING &&
        console.log(
          'recoil, basicFilterEnabledSearchListSelectorsFamily sourceSearchParam',
          sourceSearchParam,
          'requestID',
          requestID,
        );

      // fetch data
      if (
        sourceSearchParam &&
        sourceSearchParam.ready &&
        sourceSearchParam.search !== ''
      ) {
        const routeParams = getParamsFromSearch<TRecordKeys>(
          sourceSearchParam.search as H.Search,
        );
        const {
          order,
          sort,
          page,
          perPage,
          simpleQueryFieldKey,
          filterId,
          useFilteredSearch,
          useSavedFilter,
        } = routeParams;
        const simpleQuery = decodeURIComponent(routeParams.simpleQuery);
        const filter = decodeURIComponent(routeParams.filter);

        try {
          let response: TEpSubsDBResponseOk<TRecord>;
          if (!useFilteredSearch) {
            throw new Error(
              `The url is not supported. useFilteredSearch cannot be false for ${source} search page.`,
            );
          }

          // Filtered Search
          const filterMetaData = {
            sort_field:
              sort !== ''
                ? {
                    field: addPrefixForFilterField(sort, source),
                    asc: order === 'ASC' ? true : false,
                  }
                : {
                    // Default Sorting is by recently created first
                    field: addPrefixForFilterField('created_at', source),
                    asc: false,
                  },
            page_index: page - 1,
            per_page: perPage,
            keyword: simpleQuery ?? '',
            element: simpleQueryFieldKey || 'all',
          };
          if (!!filterId && filterId > 0 && useSavedFilter) {
            response = await genSavedFilteredSearchData(source, filterId, filterMetaData);
          } else {
            const parsedFilter =
              filter !== '' ? (JSON.parse(filter) as TFilterEntry[]) : [];
            response = await genFilteredSearchData(source, {
              ...filterMetaData,
              filters: parsedFilter,
            });
          }
          // // End of the useFilteredSearch branch
          // }

          DEBUGGING &&
            console.log(
              `recoil, ${source} ,basicFilterEnabledSearchListSelectorsFamily response`,
              sort,
              response,
            );

          return {
            loading: false,
            err: NO_ERROR_IN_TABLE,
            data:
              // We could avoid this unknown walk around if we could figure a way out
              // to establish the type relation between TRecordInList and TRecord
              response.data
                ? response.data.map(dataInSourceType => {
                    return {
                      [source]: dataInSourceType,
                    } as unknown as TRecordInList;
                  })
                : [],
            total: response.total ?? 0,
          };
        } catch (error) {
          console.log(`error`, error);
          return {
            loading: false,
            err: (error as Error).message,
            data: [],
            total: 0,
          };
        }
      }

      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: [],
        total: 0,
      };
    },
});
