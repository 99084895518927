import axios from 'axios';
import { TCreditNoteType } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsCreditNoteForm.js';
import { hostUrl } from '../config.js';
import {
  IEpSubsCompositeDBCredit_noteValue,
  IEpSubsDBCredit_notePost,
  IEpSubsDBCredit_noteValue,
  IEpSubsDBCredit_note_commentValue,
  IEpSubsDBReason_codeValue,
  TEpSubsDBCredit_noteValueKeys,
  TEpSubsDBResponseOk,
  IEpSubsDBCredit_note_activityValue,
} from '../models';
import { getSortSQLStrFromSortingItems, TSortingItem } from './epSubsServiceUtils';
import { ICreditNoteRefundForm } from '../containers/creditNotes/creditNoteDetail/creditNoteActions/EpSubsCreditNoteRefundForm';
import { IWriteOffVoidFormData } from 'src/containers/invoices/invoiceDetail/invoiceActions/EpSubsWriteOffVoidForm.js';

export const genCreditNoteListData = async (
  perPage = 10,
  pageIdx = 1,
  sortingItems: Array<TSortingItem<TEpSubsDBCredit_noteValueKeys>> = [],
  simpleQuery = '',
): Promise<TEpSubsDBResponseOk<IEpSubsDBCredit_noteValue>> => {
  const sortSQLStr =
    getSortSQLStrFromSortingItems<TEpSubsDBCredit_noteValueKeys>(sortingItems);
  return axios
    .get(
      `${hostUrl}/credit-notes/search?` +
        [
          `key=${simpleQuery}`,
          `sort=${sortSQLStr}`,
          `per_page=${perPage}`,
          `page=${pageIdx - 1}`,
        ].join('&'),
    )
    .then(response => {
      return response.data;
    });
};

/**
 * Api call for credit note detail of given id
 * @returns response with the detail data
 */
export const genCreditNoteData = async (
  id: number,
): Promise<IEpSubsCompositeDBCredit_noteValue> => {
  return axios.get(`${hostUrl}/credit-notes/${id}?limit=-1`).then(response => {
    return response.data;
  });
};

export const genCreditNoteActivityData = async (
  id: number,
  limit: number,
): Promise<IEpSubsDBCredit_note_activityValue[]> => {
  return axios
    .get(`${hostUrl}/credit-notes/${id}/actlogs?limit=${limit}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreditNoteActivityDetail = async (
  actLogId: number,
): Promise<IEpSubsDBCredit_note_activityValue> => {
  return axios.get(`${hostUrl}/credit-notes/actlogs/${actLogId}`).then(response => {
    return response.data;
  });
};

export const genCreditNoteCommentData = async (
  id: number,
  limit?: number,
): Promise<IEpSubsDBCredit_note_commentValue[]> => {
  return axios
    .get(`${hostUrl}/credit-notes/${id}/comments?${limit ? 'limit=' + limit : ''}`)
    .then(response => {
      return response.data.data;
    });
};

export const genCreateCreditNoteComment = async (
  data: FormData,
  removeAllUploadedImage: () => void,
): Promise<string> => {
  return axios.post(`${hostUrl}/credit-notes/comments`, data).then(response => {
    removeAllUploadedImage();
    return response.data.data;
  });
};

export const genCreditNotes = async (
  id: number,
  pageNum: number,
  source: 'subscriptions' | 'customers',
  perPage: number,
): Promise<TEpSubsDBResponseOk<IEpSubsDBCredit_noteValue>> => {
  return axios
    .get(`${hostUrl}/${source}/${id}/credit-notes?page=${pageNum}&per_page=${perPage}`)
    .then(response => {
      return response.data;
    });
};

export const genCreateCreditNote = async (
  data: IEpSubsDBCredit_notePost,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/credit-notes/create/${data.type as TCreditNoteType}`, data)
    .then(response => {
      return response.data.data;
    });
};

export const genReasonCodes = async (
  type: 'create_credit_note' | 'cancel_subscription',
): Promise<IEpSubsDBReason_codeValue[]> => {
  return axios.get(`${hostUrl}/ref/reason-code/${type}`).then(response => {
    return response.data;
  });
};

/**
 * Api call for online CreditNote refund
 * @returns response
 */

export const genCreditNoteRefund = async (
  id: number,
  data: ICreditNoteRefundForm,
): Promise<string> => {
  return axios.post(`${hostUrl}/credit-notes/${id}/refund`, data).then(response => {
    return response.data;
  });
};

/**
 * Api call for off-line invoice refund
 * @param data from user
 * @returns response
 */
export interface ICreditNoteRecordRefundFormDB {
  amount: number;
  refund_date: string;
  refund_method: string;
  reference_number: string;
  comment: string;
}
export const genCreditNoteRecordRefund = async (
  id: number,
  data: ICreditNoteRecordRefundFormDB,
): Promise<string> => {
  return axios
    .post(`${hostUrl}/credit-notes/${id}/record-refund`, data)
    .then(response => {
      return response.data;
    });
};

export const genRemoveAllocation = async (alloc_id: number): Promise<boolean> => {
  return axios.post(`${hostUrl}/credit-notes/${alloc_id}/remove-alloc`).then(() => {
    return true;
  });
};

export const genVoidCreditNote = async (
  id: number,
  data: IWriteOffVoidFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/credit-notes/${id}/void`, data).then(() => {
    return true;
  });
};

export const genDeleteCreditNote = async (
  id: number,
  data: IWriteOffVoidFormData,
): Promise<boolean> => {
  return axios.post(`${hostUrl}/credit-notes/${id}/delete`, data).then(() => {
    return true;
  });
};
