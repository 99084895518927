import { useRecoilCallback } from 'recoil';
import { TTabSource } from 'src/components/tabs/EpSubsTabList';
import {
  creditNoteRefreshRequestIDAtoms,
  currentDetailPageCreditNoteTabSelectors,
  CURRENT_ID_ATOM_DEFAULT_VALUE,
  invoiceRefreshRequestIDAtoms,
  transactionRefreshRequestIDAtoms,
} from '..';
import { currentSubscriptionIdAtom } from '../subscription/subscriptionDetailPageStates';
import { currentCustomerIdAtom } from './customerDetailPageStates';
import {
  currentDetailPageCreditNoteTabRefreshRequestIDAtoms,
  currentDetailPageEmailLogTabRefreshRequestIDAtoms,
  currentDetailPageGiftCardTabRefreshRequestIDAtoms,
  currentDetailPageInvoiceTabRefreshRequestIDAtoms,
  currentDetailPageInvoiceTabSelectors,
  currentDetailPagePromotionalCreditTabRefreshRequestIDAtoms,
  currentDetailPageTransactionTabRefreshRequestIDAtoms,
  currentDetailPageTransactionTabSelectors,
} from './detailPageTabStates';
import { DEBUGGING } from 'src/config';

/**
 * How to get current value in useRecoilCallback in hook with snapshot:
 * https://github.com/facebookexperimental/Recoil/issues/829#issuecomment-792904458
 * @example:
 *  id = snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() || CURRENT_ID_ATOM_DEFAULT_VALUE;
 */

// *********************************************** //
//       Detail Page Invoice List Tab Hooks        //
// *********************************************** //

/**
 * Refresh Invoice List Tab in either customer or subscription detail page.
 * @param source either 'customers' or 'subscriptions', defined in TTabSource.
 * @param associatedSourceId customerId or subscriptionID:
 * To avoid unnecessary refresh, we request it
 * to compare with the value of current[ Customer | Subscription ]IdAtom
 * It will refresh only if they match, which means
 * the data for the corresponding page has been cached
 * and will not refresh automatically if we simply go to the that page.
 */
export const useRefreshCurrentDetailPageInvoiceTab = (): ((
  source: TTabSource,
  pageNum: number,
  associatedSourceId: number,
  perPage: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (source, pageNum, associatedSourceId, perPage) => {
        let id: number | null;
        switch (source) {
          case 'customers':
            id =
              snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
          case 'subscriptions':
            id =
              snapshot.getLoadable(currentSubscriptionIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
        }
        if (id === associatedSourceId) {
          // refresh detail Page Invoice List Tab list
          set(
            currentDetailPageInvoiceTabRefreshRequestIDAtoms(source),
            requestID => requestID + 1,
          );
          const invoices =
            snapshot
              .getLoadable(
                currentDetailPageInvoiceTabSelectors({ source, pageNum, perPage }),
              )
              .valueMaybe()?.data || [];
          invoices.map(invoice =>
            set(invoiceRefreshRequestIDAtoms(invoice.id), requestID => requestID + 1),
          );
        }
      },
    [],
  );
};

// *********************************************** //
//       Detail Page CreditNote List Tab Hooks     //
// *********************************************** //

/**
 * Refresh CreditNote List Tab in either customer or subscription detail page.
 * @param source either 'customers' or 'subscriptions', defined in TTabSource.
 * @param associatedSourceId customerId or subscriptionID:
 * To avoid unnecessary refresh, we request it
 * to compare with the value of current[ Customer | Subscription ]IdAtom
 * It will refresh only if they match, which means
 * the data for the corresponding page has been cached
 * and will not refresh automatically if we simply go to the that page.
 */
export const useRefreshCurrentDetailPageCreditNoteTab = (): ((
  source: TTabSource,
  pageNum: number,
  associatedSourceId: number,
  perPage: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (source, pageNum, associatedSourceId) => {
        let id: number | null;
        switch (source) {
          case 'customers':
            id =
              snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
          case 'subscriptions':
            id =
              snapshot.getLoadable(currentSubscriptionIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
        }
        if (id === associatedSourceId) {
          // refresh detail Page CreditNote List Tab list
          DEBUGGING &&
            console.log(
              `recoil, Refresh creditNote in ${source} detail page ${associatedSourceId}`,
            );
          set(
            currentDetailPageCreditNoteTabRefreshRequestIDAtoms(source),
            requestID => requestID + 1,
          );
          const creditNotes =
            snapshot
              .getLoadable(
                currentDetailPageCreditNoteTabSelectors({
                  source,
                  pageNum: pageNum,
                  perPage: 10,
                }),
              )
              .valueMaybe()?.data || [];
          creditNotes.map(creditNote =>
            set(
              creditNoteRefreshRequestIDAtoms(creditNote.id),
              requestID => requestID + 1,
            ),
          );
        }
      },
    [],
  );
};

// *********************************************** //
//     Detail Page Transaction List Tab Hooks      //
// *********************************************** //

/**
 * Refresh Transaction List Tab in either customer or subscription detail page.
 * @param source either 'customers' or 'subscriptions', defined in TTabSource.
 * @param associatedSourceId customerId or subscriptionID:
 * To avoid unnecessary refresh, we request it
 * to compare with the value of current[ Customer | Subscription ]IdAtom
 * It will refresh only if they match, which means
 * the data for the corresponding page has been cached
 * and will not refresh automatically if we simply go to the that page.
 */
export const useRefreshCurrentDetailPageTransactionTab = (): ((
  source: TTabSource,
  pageNum: number,
  associatedSourceId: number,
  perPage: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (source, pageNum, associatedSourceId) => {
        let id: number | null;
        switch (source) {
          case 'customers':
            id =
              snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
          case 'subscriptions':
            id =
              snapshot.getLoadable(currentSubscriptionIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
        }
        if (id === associatedSourceId) {
          // refresh detail Page Transaction List Tab list
          set(
            currentDetailPageTransactionTabRefreshRequestIDAtoms(source),
            requestID => requestID + 1,
          );
          const transactions =
            snapshot
              .getLoadable(
                currentDetailPageTransactionTabSelectors({
                  source,
                  pageNum: pageNum,
                  perPage: 10,
                }),
              )
              .valueMaybe()?.data || [];
          transactions.map(transaction =>
            set(
              transactionRefreshRequestIDAtoms(transaction.id),
              requestID => requestID + 1,
            ),
          );
        }
      },
    [],
  );
};

// *********************************************** //
//   Detail Page PromotionalCredit List Tab Hooks  //
// *********************************************** //

/**
 * Refresh PromotionalCredit List Tab in either customer or subscription detail page.
 * @param source 'customers', defined in TTabSource.
 * @param associatedSourceId customerId:
 * To avoid unnecessary refresh, we request it
 * to compare with the value of currentCustomerIdAtom
 * It will refresh only if they match, which means
 * the data for the corresponding page has been cached
 * and will not refresh automatically if we simply go to the that page.
 */
export const useRefreshCurrentDetailPagePromotionalCreditTab = (): ((
  source: Extract<TTabSource, 'customers'>,
  associatedSourceId: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (source, associatedSourceId) => {
        let id: number | null;
        switch (source) {
          case 'customers':
            id =
              snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
        }
        if (id === associatedSourceId) {
          // refresh detail Page PromotionalCredit List Tab list
          set(
            currentDetailPagePromotionalCreditTabRefreshRequestIDAtoms(source),
            requestID => requestID + 1,
          );
        }
      },
    [],
  );
};

// *********************************************** //
//     Detail Page EmailLog List Tab Hooks      //
// *********************************************** //

/**
 * Refresh EmailLog List Tab in either customer or subscription detail page.
 * @param source either 'customers' or 'subscriptions', defined in TTabSource.
 * @param associatedSourceId customerId or subscriptionID:
 * To avoid unnecessary refresh, we request it
 * to compare with the value of current[ Customer | Subscription ]IdAtom
 * It will refresh only if they match, which means
 * the data for the corresponding page has been cached
 * and will not refresh automatically if we simply go to the that page.
 */
export const useRefreshCurrentDetailPageEmailLogTab = (): ((
  source: TTabSource,
  associatedSourceId: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (source, associatedSourceId) => {
        let id: number | null;
        switch (source) {
          case 'customers':
            id =
              snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
          case 'subscriptions':
            id =
              snapshot.getLoadable(currentSubscriptionIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
        }
        if (id === associatedSourceId) {
          // refresh detail Page EmailLog List Tab list
          set(
            currentDetailPageEmailLogTabRefreshRequestIDAtoms(source),
            requestID => requestID + 1,
          );
        }
      },
    [],
  );
};

// *********************************************** //
//       Detail Page Gift Card List Tab Hooks        //
// *********************************************** //

/**
 * Refresh Gift Card Tab in either customer or subscription detail page.
 * @param source either 'customers' or 'subscriptions', defined in TTabSource.
 * @param associatedSourceId customerId or subscriptionID:
 * To avoid unnecessary refresh, we request it
 * to compare with the value of current[ Customer | Subscription ]IdAtom
 * It will refresh only if they match, which means
 * the data for the corresponding page has been cached
 * and will not refresh automatically if we simply go to the that page.
 */
export const useRefreshCurrentDetailPageGiftCardTab = (): ((
  source: TTabSource,
  associatedSourceId: number,
) => Promise<void>) => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (source, associatedSourceId) => {
        let id: number | null;
        switch (source) {
          case 'customers':
            id =
              snapshot.getLoadable(currentCustomerIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
          case 'subscriptions':
            id =
              snapshot.getLoadable(currentSubscriptionIdAtom).valueMaybe() ||
              CURRENT_ID_ATOM_DEFAULT_VALUE;
            break;
        }
        if (id === associatedSourceId) {
          // refresh detail Page Invoice List Tab list
          set(
            currentDetailPageGiftCardTabRefreshRequestIDAtoms(source),
            requestID => requestID + 1,
          );
        }
      },
    [],
  );
};
