import { useRecoilCallback } from 'recoil';
import {
  allProductsRequestIDAtom,
  // currentProductActivitiesSearchParamAtom,
  // currentProductCommentsSearchParamAtom,
  productRefreshRequestIDAtoms,
  productSearchRequestIDAtom,
} from '..';

import { genArchiveProduct } from 'src/services';
import { putNoty } from 'src/utilities/epSubsNoty';

// *********************************************** //
//                Product List Page Hooks             //
// *********************************************** //

export const useRefreshProductList = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        // refresh product list
        set(productSearchRequestIDAtom, requestID => requestID + 1);
        set(allProductsRequestIDAtom, requestID => requestID + 1);
      },
    [],
  );
};

export const useRefreshProduct = (): ((id: number) => Promise<void>) => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number) => {
        // refresh product
        set(productRefreshRequestIDAtoms(id), requestID => requestID + 1);
      },
    [],
  );
};

export const useArchiveProduct = (): any => {
  return useRecoilCallback(
    ({ set }) =>
      async (id: number, active: boolean, setButtonDisable: any) => {
        setButtonDisable(true);
        try {
          const archiveSuccess = await genArchiveProduct(id, active);
          if (archiveSuccess) {
            const message = `Successfully ${
              active ? 'activated' : 'archived'
            } product with id ${id}.`;
            putNoty({ type: 'success', text: message });
          }
        } catch (error) {
          console.log(`error`, error);
        }

        // refresh product
        setTimeout(() => {
          set(productRefreshRequestIDAtoms(id), requestID => requestID + 1);
          setButtonDisable(false);
        }, 1000);
      },
    [],
  );
};
