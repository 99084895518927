import React, { useEffect, useState, useRef } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, FieldError, FieldValues } from 'react-hook-form';
import ReactSelect from 'react-select';
import style from './EpSubsFormComponents.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldName, SetFieldValue, SetValueConfig, Control } from 'react-hook-form';
import { customerPhoneNumberEnteredAtom } from 'src/recoil';
import { useSetRecoilState } from 'recoil';
import {
  ESGeneralDropdownInput,
  IESGeneralDropdownInputProps,
  TGroupedOptions,
} from '../dropdownSelect/ESGeneralDropdownInput';
import { correctEmailRegex } from 'src/utilities/regexes';
// import { TEpSubsAddressDuplication } from 'src/models/epSubsCompositeDBTypes';
const MAX_NAME_LENGTH = 50;
export const UNSELECTED_VALUE_FOR_REQUIRED_DROPDOWN = '';
export const UNUSED_REGISTER_PLACEHOLDER = () => {
  null;
};
type IESFormLabelProps = {
  fieldName: string;
};
// How to use: <ESFormLabel fieldName='Email*'/>
export const ESFormLabel = ({ fieldName }: IESFormLabelProps): JSX.Element => {
  return <Form.Label className={style.form_row_label_text}>{fieldName}</Form.Label>;
};

export type TUseFormSetValue<TFieldValues extends FieldValues> = (
  name: FieldName<TFieldValues>,
  value: SetFieldValue<TFieldValues>,
  config?: SetValueConfig,
) => void;

// *********************************************** //
//            ESFormSimpleField (Text)             //
// *********************************************** //
export type TRef =
  | ((instance: HTMLInputElement | null) => void)
  | React.RefObject<HTMLInputElement>;
type IESFormTextInputProps<TValue> = {
  placeholder: string;
  controlId?: string;
  name: string;
  required?: boolean;
  ref?: TRef;
  register?: any;
  defaultValue: TValue extends string ? TValue : string;
  errorField?: FieldError;
  validate?: () => boolean;
  onChange?: () => void;
  isLoading?: boolean;
  extraStatusElement?: JSX.Element;
  maxLength?: number;
};

export function ESFormTextInput<TValue>({
  placeholder,
  controlId,
  name,
  required = false,
  ref,
  register,
  defaultValue,
  errorField,
  validate,
  onChange,
  maxLength,
}: IESFormTextInputProps<TValue>): JSX.Element {
  let rules: any = {
    maxLength: {
      value: maxLength ? maxLength : MAX_NAME_LENGTH,
      message: `There should be no more than ${
        maxLength ? maxLength : MAX_NAME_LENGTH
      } characters in ${name}.`,
    },
  };
  if (validate) {
    rules = { ...rules, validate };
  }
  const validateMessage = '';
  return (
    <>
      <Form.Control
        id={controlId}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        required={required}
        ref={ref ?? register(rules)}
        onChange={onChange}
      />
      {errorField && <div className={style.error}>{errorField.message}</div>}
      {validate && validate() === false ? (
        <div className={style.error}>{validateMessage}</div>
      ) : (
        <div></div>
      )}
    </>
  );
}

type IESFormSimpleFieldProps<TValue> = IESFormTextInputProps<TValue> & {
  label: string;
  controlId: string;
};
// Default to check max length only. But can be overwrite by ref.
export function ESFormSimpleField<TValue>({
  label,
  controlId,
  placeholder,
  name,
  required = false,
  ref,
  register,
  defaultValue,
  errorField,
  validate,
  onChange,
  isLoading = false,
  extraStatusElement = <></>,
  maxLength,
}: IESFormSimpleFieldProps<TValue>): JSX.Element {
  return (
    <Form.Group as={Row} controlId={controlId}>
      <Col md={4} className="text-right">
        <Form.Label className={style.form_row_label_text}>{label}</Form.Label>
        {required && <Form.Label className={style.form_row_label_star}>*</Form.Label>}
      </Col>

      <Col md={4}>
        <ESFormTextInput
          placeholder={placeholder}
          name={name}
          required={required}
          ref={ref}
          register={register}
          defaultValue={defaultValue}
          errorField={errorField}
          validate={validate}
          onChange={onChange}
          maxLength={maxLength}
        />
      </Col>
      {isLoading && (
        <Col md={1} className="mt-2">
          <Spinner animation="border" size="sm" />
        </Col>
      )}
      {extraStatusElement}
    </Form.Group>
  );
}

// *********************************************** //
//            ESFormSimpleDropdownField            //
// *********************************************** //
type IESFormDropdownInputProps<TValue extends string> =
  IESGeneralDropdownInputProps<TValue> & {
    name: string;
    required?: boolean;
    register: any;
    control?: Control;
    validate?: () => boolean;
    dropDownId?: string;
  };

type SelectOption = {
  label: string;
  value: string;
  groupName?: string;
};
type IsMulti = false;

/**
 * @param onChange: type = [(optionValue: string) => void]. Define Side Effect in Addition to normal dropdown value change
 */
export function ESFormDropdownInput<TValue extends string>(
  props: IESFormDropdownInputProps<TValue>,
): JSX.Element {
  const {
    placeholder,
    controlId = '',
    name,
    required = false,
    control,
    defaultValue,
    disabled = false,
    options,
    validate,
    register,
    displayOptionGroup = false,
  } = props;
  const selectRef = useRef<ReactSelect<
    SelectOption,
    IsMulti,
    TGroupedOptions<SelectOption>
  > | null>(null);

  let selectDefaultValue: SelectOption | undefined | null = options
    .map(option => {
      return {
        type: option.type,
        value: String(option.value),
        label: option.label,
        groupName: option.groupName,
      };
    })
    .find(option => option.value === defaultValue);
  let controllerDefaultValue: string | null; // only store key, not option object

  if (!selectDefaultValue) {
    // if undefined
    selectDefaultValue = null;
    controllerDefaultValue = null;
  } else {
    controllerDefaultValue = selectDefaultValue.value;
  }

  let rules = {};
  if (required) {
    rules = { required: 'The field must be provided' };
  }
  if (validate) {
    rules = { validate };
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        ref={register()}
        defaultValue={controllerDefaultValue}
        rules={rules}
        onFocus={() => {
          if (selectRef.current !== null) selectRef.current.focus();
        }}
        render={({ onChange, onBlur, value }) => {
          return (
            <ESGeneralDropdownInput
              controlId={controlId}
              disabled={disabled}
              placeholder={placeholder}
              options={options}
              displayOptionGroup={displayOptionGroup}
              renderProps={{
                onChange,
                onBlur,
                value,
              }}
              // // The ref enables explorer to focus on the dropdown
              // // if there is validation error for this field when submit form
              // ref={ref => (selectRef.current = ref)}
              onChange={props.onChange}
              selectDefaultValue={selectDefaultValue}
              // selectRef={selectRef}
              onSetRef={ref => (selectRef.current = ref)}
            />
          );
        }}
      />
    </>
  );
}

type IESFormSimpleDropdownFieldProps<TValue extends string> =
  IESFormDropdownInputProps<TValue> & {
    label: string;
    controlId: string;
  };
export function ESFormSimpleDropdownField<TValue extends string>({
  label,
  controlId,
  placeholder,
  name,
  required = false,
  register,
  control,
  defaultValue,
  options,
  disabled,
  variant = 'main',
  validate,
  onChange,
}: IESFormSimpleDropdownFieldProps<TValue>): JSX.Element {
  const dropdown = (
    <ESFormDropdownInput
      placeholder={placeholder}
      controlId={controlId}
      name={name}
      required={required}
      register={register}
      control={control}
      defaultValue={defaultValue}
      options={options}
      disabled={disabled}
      validate={validate}
      onChange={onChange}
    />
  );
  switch (variant) {
    case 'main':
      return (
        <Form.Group as={Row} controlId={controlId}>
          <Col md={4} className="text-right">
            <Form.Label className={style.form_row_label_text}>{label}</Form.Label>
            <Form.Label className={style.form_row_label_star}>*</Form.Label>
          </Col>
          <Col md={4}>{dropdown}</Col>
        </Form.Group>
      );
    case 'side':
      return (
        <Form.Group
          className="w-100"
          controlId={controlId}
          style={{ marginBottom: '0px' }}
        >
          <Form.Label>{`${label}`}</Form.Label>
          {dropdown}
        </Form.Group>
      );
    default:
      return <></>;
  }
}

// *********************************************** //
//                  ESFormEmailInput               //
// *********************************************** //
type ESFormEmailInputProps = {
  defaultEmail?: string | null;
  namePath: string;
  register: any;
  error?: FieldError;
  required?: boolean;
  disabled?: boolean;
  messageForMissing?: string;
  id?: string;
};

/**
 * Field Name: `${namePath}email` or `email` when namePath is not provided
 */
export const ESFormEmailInput = ({
  defaultEmail,
  namePath = '',
  register,
  error,
  required = false,
  disabled = false,
  messageForMissing = 'Please input email.',
  id,
}: ESFormEmailInputProps): JSX.Element => {
  return (
    <>
      <Form.Control
        id={`${id}`}
        placeholder="Email"
        disabled={disabled}
        defaultValue={defaultEmail ?? undefined}
        name={`${namePath}email`}
        ref={register({
          maxLength: {
            value: MAX_NAME_LENGTH,
            message: `There should be no more than ${MAX_NAME_LENGTH} characters in email name.`,
          },
          pattern: {
            value: correctEmailRegex,
            message: 'Invalid email address',
          },
          required: required && messageForMissing,
        })}
      />
      {error && <div className={style.error}>{error.message}</div>}
    </>
  );
};

// *********************************************** //
//                  ESFormPhoneInput               //
// *********************************************** //
type ESFormPhoneInputProps = {
  defaultPhone: string;
  namePath: string;
  register: any;
  setValue: any;
  error?: FieldError;
};

export const ESFormPhoneInput = ({
  defaultPhone,
  namePath = '',
  register,
  setValue,
  error,
}: ESFormPhoneInputProps): JSX.Element => {
  const [err, setErr] = useState(error);
  const setCustomerPhoneNumberEntered = useSetRecoilState(customerPhoneNumberEnteredAtom);
  useEffect(() => {
    setErr(error);
  }, [error]);

  return (
    <>
      <Form.Control
        placeholder="Phone"
        defaultValue={defaultPhone}
        name={`${namePath}phoneNum`}
        type="tel"
        ref={register()}
        onChange={e => {
          setValue(`${namePath}phoneNum`, e.target.value);
          setCustomerPhoneNumberEntered(e.target.value.length > 0);
          if (e.target.value.length === 0) {
            setValue(`${namePath}phone_type`, UNSELECTED_VALUE_FOR_REQUIRED_DROPDOWN);
          }
        }}
      />
      {err?.message && <pre className={style.error}>{err.message}</pre>}
    </>
  );
};
