import * as React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { ESimpleSearchFields, TTabOptionItem } from 'src/js/constants/subs';
// import { ESGeneralRadioInput } from 'src/components/radioSelect/ESGeneralRadioInput';
import { Col, Row } from 'react-bootstrap';
import EpSubsHorizontalTabNav from 'src/components/tabs/EpSubsHorizontalTabNav';
import { DEBUGGING } from 'src/config';
import { useHistory } from 'react-router-dom';

interface Props<TFieldKeys extends string | typeof ESimpleSearchFields.ALL> {
  onSearch: (
    result: {
      input: string;
      field?: TFieldKeys | typeof ESimpleSearchFields.ALL;
    },
    event?: React.BaseSyntheticEvent,
  ) => void;
  // simple query string
  simpleQuery?: string;
  placeHolder?: string;
  // simple query key
  enableSimpleSearchByField?: boolean;
  simpleQueryFieldKey?: TFieldKeys | typeof ESimpleSearchFields.ALL;
  simpleQueryDefaultFieldKey?: TFieldKeys | typeof ESimpleSearchFields.ALL;
  simpleQueryFieldOptions?: TTabOptionItem<string>[];
}

function SimpleSearchBar<TFieldKeys extends string = ESimpleSearchFields>(
  props: Props<TFieldKeys>,
): JSX.Element {
  const history = useHistory();
  const {
    placeHolder = 'Search by ID / Name',
    enableSimpleSearchByField = false,
    simpleQueryFieldKey,
    simpleQueryDefaultFieldKey = ESimpleSearchFields.ALL as TFieldKeys,
    simpleQueryFieldOptions,
  } = props;
  const [isValid, setIsValid] = useState(true);
  const [userInput, setUserInput] = useState(props.simpleQuery ?? '');
  const [chosenField, setChosenField] = useState<
    TFieldKeys | typeof ESimpleSearchFields.ALL
  >();

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    setUserInput(e.target.value);
    setIsValid(() => String(e.target.value).length <= 200);
  };
  const handleInputFieldKeyChange = (
    field?: TFieldKeys | string,
    event?: React.BaseSyntheticEvent,
  ) => {
    field && setChosenField(field as TFieldKeys);
    field && props.onSearch({ input: userInput, field: field as TFieldKeys }, event);
  };

  const result = { input: userInput, field: chosenField };
  DEBUGGING && console.log('user input value:', userInput);
  const urlString = window.location.search;
  const urlParams = new URLSearchParams(urlString);
  DEBUGGING && console.log('checking on the url params:', urlParams.get('filter'));

  const inputRef = React.useRef(null);
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleButtonClick();
    }
  };
  const allFilterData = localStorage.getItem('filterData');
  const handleButtonClick = () => {
    if (
      (userInput === '' && window.location.toString().includes('customers')) ||
      (userInput === '' && window.location.toString().includes('subscriptions')) ||
      (userInput === '' && window.location.toString().includes('invoices'))
    ) {
      const searchParams = new URLSearchParams();
      searchParams.set(
        'filter',
        `${allFilterData ? allFilterData : urlParams.get('filter')}`,
      );
      searchParams.set('page', `1`);
      searchParams.set('perPage', `10`);
      searchParams.delete('simpleQuery');
      searchParams.delete('simpleQueryFieldKey');
      searchParams.delete('filterId');
      searchParams.delete('useFilteredSearch');
      searchParams.delete('useSavedFilter');

      const newUrl = `?${searchParams.toString()}`;
      DEBUGGING && console.log('Navigating to:', newUrl);

      history.replace(newUrl);
    } else {
      props.onSearch(result);
    }
  };
  return (
    <Form>
      <InputGroup>
        <Col className="pl-0">
          <Row className="mb-3 pl-0 mr-auto flex">
            <Col style={{ maxWidth: '450px' }}>
              <Row className="ml-0">
                <FormControl
                  id="Ep_simpleSearchInput"
                  placeholder={placeHolder}
                  aria-label="Placeholder"
                  aria-describedby="basic-addon2"
                  className={'mr-3 rounded'}
                  value={userInput}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  ref={inputRef}
                />
              </Row>
              <Row className="ml-0" style={{ color: 'red' }}>
                {isValid ? '' : 'Cannot be longer than 40 characters.'}
              </Row>
            </Col>
            <Col>
              <Button
                id="Ep_search"
                variant="primary"
                style={{ boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}
                onClick={handleButtonClick}
                disabled={!isValid}
              >
                Search
              </Button>
            </Col>
          </Row>
          {enableSimpleSearchByField && (
            <EpSubsHorizontalTabNav<TFieldKeys | typeof ESimpleSearchFields.ALL>
              tabs={simpleQueryFieldOptions ?? []}
              defaultKey={simpleQueryFieldKey ?? simpleQueryDefaultFieldKey}
              onSelect={handleInputFieldKeyChange}
              controlId="#simpleSearchInputFieldTabGroup"
              disabled={!isValid}
            />
          )}
        </Col>
      </InputGroup>
    </Form>
  );
}

export default SimpleSearchBar;
