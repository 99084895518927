import {
  NO_ERROR_IN_TABLE,
  TLocationRecord,
} from '../../components/tables/EpFullFeaturedTable';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import {
  IEpSubsCompositeDBCustomerValueInList,
  IEpSubsCompositeDBCustomerValue,
  IEpSubsDBCustomerValueList,
} from '../../models/';
import { genCustomerData } from '../../services/epSubsCustomerService';
import { ListState } from '../../components/tables/EpFullFeaturedTable';
import { basicFilterEnabledSearchListSelectorsFamily } from '..';
import { getNoFilterOptionBySource } from 'src/components/navBars/EpSubsListNav/EpSubsListNav';
import { getDefaultFilterSearchParam } from 'src/components/navBars/EpSubsListNav/EpSubsListNavUtil';
import { DEBUGGING } from 'src/config';

// *********************************************** //
//   Customer List Latest Search Parameter States  //
// *********************************************** //
export const DEFAULT_CUSTOMER_SEARCH_PARAMETER = getDefaultFilterSearchParam(
  getNoFilterOptionBySource('customer')?.filterJson,
);

/**
 * An atom that stores the latest customer search param
 */
export const latestCustomerSearchParamAtom = atom<TLocationRecord>({
  key: 'latestCustomerSearchParamAtom',
  default: {
    search: DEFAULT_CUSTOMER_SEARCH_PARAMETER,
    ready: false,
  },
});

// *********************************************** //
//       Customer List Search Result States        //
// *********************************************** //

/**
 * An atom that stores that stores the refresh Request ID for customer list
 */
export const customerSearchRequestIDAtom = atom<number>({
  key: 'customerSearchRequestIDAtom',
  default: 0,
});

/**
 * An selector that stores the customer detail list based on current searchParam
 */
export const customerCompleteListSelector = selector<
  ListState<IEpSubsDBCustomerValueList>
>({
  key: 'customerCompleteListSelector',
  get: async ({ get }) => {
    // const idListState = get(customerBasicListSelector);
    const idListState = get(
      basicFilterEnabledSearchListSelectorsFamily('customer'),
    ) as ListState<IEpSubsCompositeDBCustomerValueInList>;

    if (!idListState.err) {
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: (idListState.data.map(index => index.customer) as any) ?? [],
        total: idListState.total,
      };
    } else {
      // if there is error, return same state from customerBasicListSelector
      return {
        loading: false,
        err: NO_ERROR_IN_TABLE,
        data: [],
        total: 0,
      };
    }
  },
});

// *********************************************** //
//           Customer Detail Family States         //
// *********************************************** //
/**
 * An atomFamily that determine whether a customer detail page has been visited for each customer
 * and the dependency of it by other pages should pull data from it.
 */
export const customerDetailEnabledAtomFamily = atomFamily<boolean, number>({
  key: 'customerDetailEnabledAtomFamily',
  default: false,
});

/**
 * An atomFamily that stores the refresh Request ID for each customer
 */
export const customerRefreshRequestIDAtoms = atomFamily<number, number>({
  key: 'customerRefreshRequestIDAtoms',
  default: 0,
});

/**
 * An selectorFamily that stores customer details for each customer
 */
export const customerSelectors = selectorFamily<IEpSubsCompositeDBCustomerValue, number>({
  key: 'customerSelectors',
  get:
    customerId =>
    async ({ get }) => {
      get(customerRefreshRequestIDAtoms(customerId)); // Add request ID as a dependency
      const customerData = await genCustomerData(customerId);
      DEBUGGING && console.log('recoil, customerSelectors customerData:', customerData);
      return customerData;
    },
});
